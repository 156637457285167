
import { defineComponent } from 'vue';
import { mobileCheck } from "../scripts/mobile-check";

export default defineComponent({
  data:() => {
    return {
      desktopLinkCoords:{
        prev:"0 0 300 1000",
        next:"400 0 600 1000",
      },
      mobileLinkCoords:{
        prev:"0 0 200 800",
        next:"210 0 400 800",
      },
    }
  },

  computed:{
    pageId(): string{
      return  String(this.$route.params.id);
    },
    currentPage(): string{
      return `https://rattlesnakerenegades.s3.amazonaws.com/img/page-${this.pageId}.jpg`;
    },
    nextPage(): string{
      return this.addPage(1);
    },
    prevPage(): string{
      return this.addPage(-1);
    },
    linkCoordinates(): object{
      const isMobile = mobileCheck();
      if (isMobile){
        return this.mobileLinkCoords;
      }
      return this.desktopLinkCoords;
    },
  },
  methods:{
    addPage(n: number): string {
      const currentPage = this.pageId;
      let pageNumber = parseInt(currentPage);

      pageNumber = pageNumber + n;
      
      let nextPage = Math.min(pageNumber, 55);
      nextPage = Math.max(nextPage,1);

      let nextPageString = "";
      if (nextPage < 10){
        nextPageString = `0${nextPage}`;
      }else{
        nextPageString = nextPage.toString();
      }
      return `./${nextPageString}`;
    },
  },
  mounted(){
    
    let touchstartX = 0
    let touchendX = 0

    const slider = document.getElementById('wrapper')!

    const handleGesture = () => {
      if (touchendX < touchstartX) this.$router.push(this.nextPage)
      if (touchendX > touchstartX) this.$router.push(this.prevPage)
    }

    slider.addEventListener('touchstart', e => {
      touchstartX = e.changedTouches[0].screenX
    })

    slider.addEventListener('touchend', e => {
      touchendX = e.changedTouches[0].screenX
      handleGesture()
    })
  },
})
