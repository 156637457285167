import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Page from "@/views/Page.vue";

const MIN_PAGE = 1;
const MAX_PAGE = 55;

const router = createRouter({
  history: createWebHistory(),
  routes:[
    {
      name:"home",
      path:"/",
      component: Home,
    },
    {
      name:"page",
      path:"/page/:id",
      component:Page
    }
  ]
});

router.afterEach(to => {
  if (to.name === "page"){
    return localStorage.setItem("lastPage", to.path! .toString());
  }
  
});

router.beforeEach((to,from,next) => {


  const lastPage = String(localStorage.getItem("lastPage"));
  const lastPageNumber = parseInt(lastPage.split("/")[2]);
  const pageInRange = (lastPageNumber > MIN_PAGE && lastPageNumber < MAX_PAGE -1);

  const shouldRedirect = Boolean(to.name === "home" && lastPage && pageInRange);
  if(shouldRedirect){
    next(`${lastPage}`)
  }else{
    next();
  }
});

export default router;
